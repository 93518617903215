import { joinStrings } from '/domain/joinStrings'
import { useTranslate } from '/machinery/I18n'
import { useScrollDepth } from '/machinery/useScrollDepth'
import { useGetHashFromUrl } from '/machinery/useGetHashFromUrl'
import { BlurredImageMask } from '/features/buildingBlocks/BlurredImageMask'
import { ImageCover } from '/features/buildingBlocks/Image'
import { ApplyButton } from '/features/pageOnly/jobDetail/ApplyButton'
import { useMediaQuery } from '@kaliber/use-media-query'

import mediaStyles from '/cssGlobal/media.css'
import styles from './AsideImages.css'

export function AsideImages({ title, locationName, hoursPerWeek, images, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const asideImages = isViewportLg ? images : [images[0]]

  const scrolledBeyondViewport = useScrollDepth({ offset: 400 })
  const hash = useGetHashFromUrl()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <Content isHidden={!scrolledBeyondViewport} layoutClassName={styles.contentLayout} {...{ title, locationName, hoursPerWeek }} />

      <div className={styles.images}>
        {asideImages.map((x, i) => (
          <ImageJob
            key={i}
            image={x}
            layoutClassName={styles.imagesLayout}
            showOverlay={scrolledBeyondViewport}
            {...{ hash }}
          />
        ))}
      </div>
    </div>
  )
}

function Content({ title, locationName, hoursPerWeek, isHidden, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentContent, isHidden && styles.isHidden, layoutClassName)}>
      {title && <span className={styles.title}>{title}</span>}

      <div className={styles.buttonAndJobInfo}>
        <p className={styles.jobInfo}>
          {hoursPerWeek ? joinStrings(locationName, hoursPerWeek) : locationName }
        </p>

        <ApplyButton layoutClassName={styles.buttonLayout} />
      </div>
    </div>
  )
}

function ImageJob({ image, hash, showOverlay, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)

  return (
    <div
      className={cx(
        styles.componentImageJob,
        layoutClassName,
        hash === 'introduction' && styles.introduction,
        hash === 'description' && styles.description,
        hash === 'benefits' && styles.benefits,
        hash === 'form' && styles.form,
      )}
    >
      {isViewportLg
        ? <ImageCover aspectRatio={3 / 7} layoutClassName={styles.imageLayout} {...{ image }} />
        : <ImageCover aspectRatio={1 / 1} layoutClassName={styles.imageLayout} {...{ image }} />
      }

      <div className={cx(styles.overlay, showOverlay && styles.showOverlay)}>
        <BlurredImageMask layoutClassName={styles.imageLayout} {...{ image }} />
      </div>
    </div>
  )
}
